import axios from "axios";

import { getValue } from "./localStorage";
import { RatingType, Review } from "../interfaces/review";
import { filtersI } from "../interfaces/profile";

axios.interceptors.request.use((req) => {
  return req;
});

// CURRENCY

export const getCurrencyList = async (page: number, maxResults: number) => {
  const res = await axios.get(
    process.env.REACT_APP_CURRENCIES_API_URL + `/list`,
    {
      params: {
        type: "money",
        cpp: maxResults,
        page,
      },
    }
  );

  return res.data;
};

export const getCurrenciesSpread = async (currencies: string) => {
  const res = await axios.get(
    process.env.REACT_APP_API_URL + `/api/v2/currencies/spreads?${currencies}`
  );

  return res.data;
};

export const getLocationSearch = async (q: string) => {
  const res = await axios.get(
    process.env.REACT_APP_SITE_URL + `/api/location/v1/uk/search/`,
      {
        params: {
          limit: 5,
          q,
        },
      }
  );

  return res.data;
};

export const saveCurrencySpread = async (currency: string, value: number) => {
  const res = await axios.put(
    process.env.REACT_APP_API_URL + `/api/v2/admin/currencies/${currency}/spread`,
    {
      value,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const deleteCurrencySpread = async (currency: string) => {
  const res = await axios.delete(
    process.env.REACT_APP_API_URL + `/api/v2/admin/currencies/${currency}/spread`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

// PROFILE

export const changeProfileType = async (
  id: string,
  type: string,
  agree: boolean
) => {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/admin/profiles/change_type`,
    {
      profile_id: id,
      profile_type: type,
      agree: agree,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

const isActiveSub = (filters: any) => {
  if (filters) {
    if (filters.subStatus === "active" && !filters.from && !filters.to)
      return "?active_subscription=";
    if (filters.from && !filters.to)
      return `?active_subscription=${filters.from}`;
    if (filters.to && !filters.from)
      return `?active_subscription=|${filters.to}`;
    if (filters.to && filters.from)
      return `?active_subscription=${filters.from}|${filters.to}`;

    if (filters.subStatus === "canceled") return "?canceled_subscription";
    if (filters.subStatus === "trial") return "?trial_subscription";
  }

  return "";
};

export const getAllProfiles = async (
  page: number = 1,
  maxResults: number,
  filters?: any
) => {
  const path =
    process.env.REACT_APP_API_URL + `/api/profiles/${isActiveSub(filters)}`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params:
      filters && filters.clientStatus !== ""
        ? {
            page,
            max_results: maxResults,
            where: { type: filters.clientStatus },
          }
        : { page, max_results: maxResults },
  });

  return res.data;
};

export const getProfileByNickname = async (
  page: number,
  nickname: string,
  filters?: filtersI
) => {
  const path =
    process.env.REACT_APP_API_URL + `/api/profiles/${isActiveSub(filters)}`;

  let where = {};

  if (filters && filters.clientStatus !== "") {
    where = { nickname: nickname, type: filters.clientStatus };
  } else {
    where = { nickname: nickname };
  }

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      page,
      max_results: 15,
      where: where,
    },
  });

  return res.data;
};

export const getProfileById = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/search/profiles";

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      profile_id: id.trim() || null,
    },
  });
  return res.data;
};

export const getProfileByPhone = async (phone: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/search/profiles";

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      phone: `${phone}` || null,
    },
  });
  return res.data;
};

export const profileInfo = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/profiles/" + id;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

export const userInfo = async () => {
  const path = process.env.REACT_APP_API_URL + "/api/auth/user_info";

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

export const profileSubscription = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL + "/api/profile_subscription/" + id;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

// LICENSE

export const licenseList = async (page: number = 1, status: string) => {
  const path =
    process.env.REACT_APP_API_URL +
    `/api/admin/licenses/?embedded={"profile_id":1}&sort=-_created` +
    (status !== "all" ? `&where={"status":"${status}"}` : "");

  const res = await axios({
    method: "GET",
    url: path,
    params: {
      page,
      max_results: 10,
    },
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const licenseListById = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL +
    `/api/admin/licenses/of/${id}?embedded={"profile_id":1}`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const updateLicense = async (id: string, name: string) => {
  const res = await axios.patch(
    process.env.REACT_APP_API_URL + `/api/admin/licenses/of/${id}`,
    {
      name: name,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const approveLicense = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL + `/api/admin/licenses/of/${id}/approve`;

  const res = await axios({
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const declineLicense = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL + `/api/admin/licenses/of/${id}/decline`;

  const res = await axios({
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const createBranch = async (
    isActive: boolean,
    address: string,
    city: number,
    metro: number,
    location: any,
    services: any,
    workTime: any,
) => {
  const res = await axios.post(
      process.env.REACT_APP_API_URL + `/api/admin/branches/`,
      {
        location: location,
        address: address,
        city: city,
        metro: metro,
        work_time: workTime,
        is_active: isActive,
        services: services,
      },
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res.data;
};

// SUBSCRIPTION

export const getProfileSubscription = async (id: string) => {
  const res = await axios.get(
    process.env.REACT_APP_API_URL + `/api/profile_subscription/` + id,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const addSubscription = async (
  id: string,
  serviceId: string,
  activeAt: string,
  count: number
) => {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/subscription`,
    {
      service_product_id: serviceId,
      active_at: activeAt,
      count_items: count,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const addCustomPayment = async (
  id: string,
  serviceProductId: string,
  paymentExpiresDays: number,
  paymentCount: number,
  countItems: number,
  amount?: number,
  periodDays?: number,
  activeAt?: string
) => {
  // const res = await axios.post(
  //   process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/subscription`,
  //   {
  //     pay_by_link: true,
  //     service_product_id: serviceProductId,
  //     payment_expires_days: paymentExpiresDays,
  //     payment_count: paymentCount,
  //     amount: amount || null,
  //     period_days: periodDays || null,
  //     active_at: activeAt || null,
  //     count_items: countItems,
  //   },
  //   {
  //     headers: {
  //       Authorization: `Bearer ${getValue("access_token")}`,
  //     },
  //   }
  // );

  const res = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/admin/profiles/${id}/subscription`,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    data: {
      pay_by_link: true,
      service_product_id: serviceProductId,
      payment_expires_days: paymentExpiresDays,
      payment_count: paymentCount,
      amount: amount || null,
      period_days: periodDays || null,
      active_at: activeAt || null,
      count_items: countItems,
    },
  });

  return res.data;
};

export const sendPaymentNotification = async (
  id: string,
  type: string,
  data: string
) => {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/pay-links`,
    {
      [type]: data,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const addTrial = async (
    id: string,
    period_days: number,
    reason: string | null,
) => {
  const res = await axios.post(
      process.env.REACT_APP_API_URL + `/api/admin/subscriptions/${id}/trial`,
      {
        period_days: period_days,
        reason: reason,
      },
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res.data;
};

export const deleteSubscription = async (id: string) => {
  const res = await axios.delete(
    process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/subscription`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const deleteProfileSubscription = async (id: string) => {
  const res = await axios.delete(
    process.env.REACT_APP_API_URL + `/api/subscriptions/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

// PHONE

export const addPhone = async (phone: string, profileId: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/admin/phones/" + phone;

  const res = await axios.post(
    path,
    {
      profile_id: profileId,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const deletePhone = async (phone: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/admin/phones/" + phone;

  const res = await axios.delete(path, {
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const patchPhone = async (phone: string, newPhone: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/admin/phones/" + phone;

  const res = await axios.patch(
    path,
    {
      new_phone: newPhone,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

// BRANCH

export const branchInfo = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/branches/" + id;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

export const getAllBranches = async (page: number = 1, isFree: boolean = false) => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/branches/`;

  const res = await axios({
    method: "GET",
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    url: path,
    params: {
      page,
      max_results: 15,
      ...(isFree ? { free: true } : {}),
    },
  });

  return res.data;
};

export const getAllAdminBranches = async (page: number = 1) => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/branches/`;

  const res = await axios({
    method: "GET",
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    url: path,
    params: {
      page,
      max_results: 15,
    },
  });

  return res.data;
};

export const getBranchPhoto = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/branches_images`;

  const res = await axios({
    method: "GET",
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    url: path,
    params: {
      where: { branch_id: id },
    },
  });

  return res.data;
};

export const postBranchPhoto = async (id: string, image: string) => {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/branches_images`,
    {
      branch_id: id,
      image: image,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const deleteBranchPhoto = async (id: string) => {
  const res = await axios.delete(
    process.env.REACT_APP_API_URL + `/api/branches_images/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const replaceBranchPhoto = async (
  id: string,
  image: string,
  branchId: string
) => {
  const res = await axios.patch(
    process.env.REACT_APP_API_URL + `/api/branches_images/${id}`,
    {
      branch_id: branchId,
      image: image,
      _id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const getBranchById = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/branches/`;

  const res = await axios({
    method: "GET",
    url: path,
    params: {
      embedded: { profile_id: 1 },
      where: { _id: `${id}` },
    },
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const getBranchByProfileId = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/branches/`;

  const res = await axios({
    method: "GET",
    url: path,
    params: {
      embedded: { profile_id: 1 },
      where: { profile_id: `${id}` },
    },
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const patchBranch = async (
  id: string,
  siteId: string,
  branchType: string,
  profileId: string,
  phoneId: string,
  location: any,
  address: string,
  city: number,
  metro: number,
  workTime: any,
  description: string,
  isActive: boolean,
  services: any

) => {
  const res = await axios.patch(
    process.env.REACT_APP_API_URL + `/api/admin/branches/${id}`,
    {
      site_id: siteId,
      branch_type: branchType,
      profile_id: profileId,
      phone_id: phoneId,
      location: location,
      address: address,
      city: city,
      metro: metro,
      work_time: workTime,
      description: description,
      is_active: isActive,
      services: services,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const getAdminBranchRateById = async (id: string) => {
  const res = await axios.get(
    process.env.REACT_APP_API_URL + `/api/admin/branches/rates`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
      params: {
        where: { branch_id: id },
      },
    }
  );

  return res.data;
};

export const getBranchRateById = async (id: string) => {
  const res = await axios.get(
    process.env.REACT_APP_API_URL + `/api/branches_rates/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const changeBranchRate = async (
  id: string,
  currency: string,
  buyValue: number,
  sellValue: number,
  buyMaxCount: number,
  buyMinCount: number,
  sellMaxCount: number,
  sellMinCount: number,
  trade?: any
) => {
  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/admin/branches/${id}/rates`,
    {
      currency: currency.toLowerCase(),
      rate: {
        buy: {
          max_count: buyMaxCount,
          min_count: buyMinCount,
          value: buyValue,
        },
        sell: {
          max_count: sellMaxCount,
          min_count: sellMinCount,
          value: sellValue,
        },
      },
      trade: trade,
    },
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const changeCustomBranchRate = async (
  courseType: string,
  sellExact: boolean,
  buyExact: boolean,
  id: string,
  currency: string,
  buyValue: number,
  sellValue: number,
  buyMaxCount: number,
  buyMinCount: number,
  sellMaxCount: number,
  sellMinCount: number,
  trade?: any
) => {
  let buy = {};
  let sell = {};
  let data = {
    currency: currency.toLowerCase(),
    rate: {},
    trade: trade,
  };

  if (!buyExact) {
    buy = {
      value: buyValue,
      min_count: buyMinCount,
      max_count: buyMaxCount,
    };
  }
  if (!sellExact) {
    sell = {
      value: sellValue,
      min_count: sellMinCount,
      max_count: sellMaxCount,
    };
  }
  if (buyExact) {
    buy = {
      value: buyValue,
      min_count: buyMinCount,
    };
  }
  if (sellExact) {
    sell = {
      min_count: sellMinCount,
      value: sellValue,
    };
  }
  if (courseType === "sell") {
    data.rate = { sell: sell };
  }
  if (courseType === "buy") {
    data.rate = { buy: buy };
  }
  if (courseType === "buy/sell") {
    data.rate = { buy: buy, sell: sell };
  }

  const res = await axios.post(
    process.env.REACT_APP_API_URL + `/api/admin/branches/${id}/rates`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

export const deleteBranchRateById = async (id: string) => {
  const res = await axios.delete(
    process.env.REACT_APP_API_URL + `/api/admin/branches/rates/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};

// REVIEW

export const reviewerInfo = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + "/api/profiles/" + id;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

export const applicationsList = async (page = 1, status = 'open', maxResults = 8) => {
  const res = await axios({
    method: "GET",
    url: process.env.REACT_APP_API_URL + `/api/admin/branches/free/owners`,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      page,
      size: maxResults,
      status,
    }
  });

  return res.data;
};

export const getFreeBranchCorrections = async (page = 1, status = 'open', maxResults = 8) => {
  const res = await axios({
    method: "GET",
    url: process.env.REACT_APP_API_URL + `/api/admin/branches/free/corrections`,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      page,
      size: maxResults,
      status,
    }
  });

  return res.data;
};
export const approveFreeBranchCorrection = async (id: string) => {
  const res = await axios.post(
      process.env.REACT_APP_API_URL + `/api/admin/branches/free/corrections/apply`,
      {
        "accepted": id
      },
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res;
};

export const declineFreeBranchCorrection = async (id: string) => {
  const res = await axios.post(
      process.env.REACT_APP_API_URL + `/api/admin/branches/free/corrections/apply`,
      {
        "rejected": [id],
      },
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res;
};

export const applicationFreeOwners = async (id: string) => {
  const path =
      process.env.REACT_APP_API_URL + `/api/admin/branches/free/owners/${id}`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const changeStatusType = async (
    id: string,
    status: string
) => {
  const res = await axios.patch(
      process.env.REACT_APP_API_URL + `/api/admin/branches/free/owners/${id}/status/${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res.data;
};


export const changeApplicationFreeOwners = async (
    id: string,
    ownerData: object
) => {
  const res = await axios.patch(
      process.env.REACT_APP_API_URL + `/api/admin/branches/free/owners/${id}`,
      {...ownerData},
      {
        headers: {
          Authorization: `Bearer ${getValue("access_token")}`,
        },
      }
  );

  return res;
};


export const reviewList = async (
  page = 1,
  filter = {
    status: "created",
    rating: "all",
  }
): Promise<any> => {
  const branchesReviesPath =
    process.env.REACT_APP_API_URL + "/api/branches/reviews";
  const profilesReviewsPath =
    process.env.REACT_APP_API_URL + "/api/profiles/reviews";

  const { status, rating } = filter;

  const ratingFilter = (rating: `${RatingType}`) => {
    switch (rating) {
      case RatingType.All:
        return undefined;
      case RatingType.Negative:
        return { $lt: 4 };
      case RatingType.Positive:
        return { $gt: 3 };
    }
  };

  const res = Promise.all([
    await axios({
      method: "GET",
      url: branchesReviesPath,
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
      params: {
        page,
        max_results: 8,
        sort: "-_updated",
        where: {
          status,
          "rating.common": ratingFilter(rating as `${RatingType}`),
        },
      },
    }).then((data) => {
      const newItems = data.data._items.map((el: Review) => {
        return { ...el, type: "branch" };
      });
      return { ...data, data: { ...data.data, _items: newItems } };
    }),

    await axios({
      method: "GET",
      url: profilesReviewsPath,
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
      params: {
        page,
        max_results: 8,
        sort: "-_updated",
        where: {
          status,
          "rating.common": ratingFilter(rating as `${RatingType}`),
        },
      },
    }).then((data) => {
      const newItems = data.data._items.map((el: Review) => {
        return { ...el, type: "profile" };
      });

      return { ...data, data: { ...data.data, _items: newItems } };
    }),
  ]);

  return res;
};

//ADDRESSES

export const getAddressChanges = async () => {
  const path = `${process.env.REACT_APP_API_URL}/api/admin/branches/`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      new_address: true,
    },
  });

  return res.data._items;
};


export const approveAddressChange = async (id: string) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/v2/admin/branches/${id}/address/approve`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};


export const rejectAddressChange = async (id: string) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/v2/admin/branches/${id}/address/reject`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getValue("access_token")}`,
      },
    }
  );

  return res.data;
};


// OTHER

export const getServiceProduct = async (type: string) => {
  const res = await axios.get(
    process.env.REACT_APP_API_URL +
      `/api/v2/products/${type}`
  );

  return res.data;
};

export const getAllCities = async () => {
  const path = process.env.REACT_APP_SITE_URL + "/api/cities";

  const res = await axios({
    method: "GET",
    url: path,
  });

  return res.data;
};

export const getAllMetro = async () => {
  const path = process.env.REACT_APP_SITE_URL + "/api/get/metro/v2/list/?locale=uk";

  const res = await axios({
    method: "GET",
    url: path,
  });

  return res.data;
};

export const subscribeInfo = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/subscription/`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const applicationInfo = async (id: string) => {
  const path =
    process.env.REACT_APP_API_URL +
    `/api/applications/?where={"profile_id":"${id}"}&max_results=1&embedded={"profile_id":1,"phone_id":1}`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });
  return res.data;
};

export const userAuthorization = async () => {
  const path = process.env.REACT_APP_MINFIN_AUTH_URL;

  const res = await axios({
    method: "GET",
    url: path,
    withCredentials: true,
  });
  return res.data;
};

export const searchByNickname = async (search = "") => {
  const path = process.env.REACT_APP_API_URL + `/api/profiles`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      where: { nickname: { $prefix: search } },
    },
  });

  return res.data;
};

export const searchByPhone = async (id: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/phones`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    params: {
      where: { profile_id: id },
    },
  });

  return res.data;
};

export const adminGetDashboard = async () => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/dashboard`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const adminPostDashboard = async (url: string, name?: string) => {
  const path = process.env.REACT_APP_API_URL + `${url}`;

  const requestOptions: any = {
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  };

  // Додаємо поле 'data', якщо 'name' визначено
  if (name) {
    requestOptions.data = {
      kind: name,
    };
  }

  const res = await axios(requestOptions);
  return res.data;
};

export const subscriptionBonusAddDays = async (profileId: string, days: number, comment?: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/profiles/${profileId}/subscription/bonus/add-days`;

  const res = await axios({
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    data: {
      days,
      ...(comment ? { comment } : {}),
    },
  });

  return res.data;
};
export const subscriptionBonusChangePrice = async (profileId: string, price: number, comment?: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/profiles/${profileId}/subscription/bonus/change-price`;

  const res = await axios({
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    data: {
      price,
      ...(comment ? { comment } : {}),
    },
  });

  return res.data;
};
export const subscriptionBonusChangeCount = async (profileId: string, count: number, comment?: string) => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/profiles/${profileId}/subscription/bonus/change-count`;

  const res = await axios({
    method: "POST",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
    data: {
      count,
      ...(comment ? { comment } : {}),
    },
  });

  return res.data;
};
export const getSubscriptionRealPrice = async (id: string) => {
  const path =
      process.env.REACT_APP_API_URL + `/api/admin/profiles/${id}/subscription/real-price`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

export const getNotifications = async () => {
  const path = process.env.REACT_APP_API_URL + `/api/admin/notifications`;

  const res = await axios({
    method: "GET",
    url: path,
    headers: {
      Authorization: `Bearer ${getValue("access_token")}`,
    },
  });

  return res.data;
};

