// components
import { CircularProgress, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  main: {
    textAlign: "center",
  },
});

export const Progress = () => {
  const cls = useStyles();

  return (
    <Box className={cls.main}>
      <CircularProgress />
    </Box>
  );
};
