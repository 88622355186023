import { useQuery } from "react-query";
import { getNotifications } from "../utils/queries";

import {
  styled,
  Container,
  AppBar,
  Box,
  makeStyles,
  Typography,
  Badge
} from "@material-ui/core";
import { NavLink } from "react-router-dom";


const useStyles = makeStyles({
  main: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "@media(max-width: 768px)": {
      flexDirection: "column",
    },
    "& > a": {
      textDecoration: "none",
    },
  },
  mainTiitle: {
    paddingRight: 8,
  },
  navLinks: {
    display: "flex",
    gap: 18,
    whiteSpace: "nowrap",
    flexWrap: "wrap",

    "& > a": {
      textDecoration: "none",
      display: "flex",
    },

    "& > a.active": {
      fontWeight: "bold",
      textDecoration: "underline",
    },

    "@media(max-width: 768px)": {
      fontSize: "13px",
      marginTop: "1rem",
    },
  },
});

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 1,
    border: `2px solid #3f51b5`,
    padding: '0 4px',
    backgroundColor: '#fff',
    color: "#3f51b5",
  },
}));

export const Header = () => {
  const cls = useStyles();

  const { data: notificationsData = {} } = useQuery("notifications", getNotifications);

  const {
    new_reviews = 0,
    corrected_free_branches = 0,
    new_licenses = 0,
    new_addresses = 0,
  } = notificationsData;

  return (
    <AppBar id="back-to-top-anchor" position="static">
      <Container maxWidth="md">
        <Box className={cls.main}>
          <NavLink to="/" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit">
            <Typography className={cls.mainTiitle} variant="h5">VA</Typography>
          </NavLink>
          <div className={cls.navLinks}>
            <NavLink to="/reviews" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              <StyledBadge badgeContent={new_reviews}>
                Відгуки
              </StyledBadge>
            </NavLink>
            <NavLink to="/applications" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              Заявки
            </NavLink>
            <NavLink to="/corrections" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              <StyledBadge badgeContent={corrected_free_branches}>
                Правки користувачів
              </StyledBadge>
            </NavLink>
            <NavLink to="/license" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              <StyledBadge badgeContent={new_licenses}>
                Ліцензії
              </StyledBadge>
            </NavLink>
            <NavLink to="/profiles" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              Профілі/Підписки
            </NavLink>
            <NavLink to="/branches" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              Обмінники
            </NavLink>
            <NavLink to="/addresses" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              <StyledBadge badgeContent={new_addresses}>
                Адреси
              </StyledBadge>
            </NavLink>
            <NavLink to="/telephones" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              Телефони
            </NavLink>
            <NavLink to="/spreads" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit" >
              Спреди
            </NavLink>
            <NavLink to="/developers" activeClassName="active" className="MuiLink-underlineHover MuiTypography-colorInherit">
              Dev
            </NavLink>
          </div>
        </Box>
      </Container>
    </AppBar>
  );
};
